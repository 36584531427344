<template>
  <b-card>
    <div class="my-2">
        <b-row class="mx-2">
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Nombre</h4>
                <h6 class="font-weight-normal">{{client.name}} {{client.last_name}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Correo</h4>
                <h6 class="font-weight-normal">{{client.email}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">R.U.N.</h4>
                <h6 class="font-weight-normal">{{client.rut}}-{{client.rut_dv}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Teléfono</h4>
                <h6 class="font-weight-normal">{{client.phone}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Nacionalidad</h4>
                <h6 class="font-weight-normal">{{client.nationality}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Tipo de Trabajador</h4>
                <h6 class="font-weight-normal">{{_workerTypeCustomer}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Estado Civil</h4>
                <h6 class="font-weight-normal">{{_civilStatusCustomer}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Antigüedad Laboral</h4>
                <h6 class="font-weight-normal">{{client.labor_old | moment("DD-MM-YYYY")}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Renta Mensual</h4>
                <h6 class="font-weight-normal">{{client.monthly_rent}}</h6>
            </b-col>
        </b-row>
    </div>
  </b-card>
</template>

<script>
  export default {
    name: 'PreapprovalDetailClient',
    props: ['client'],
    data() {
      return {
      }
    },
    computed:{
      _civilStatusCustomer() {
        switch (this.client.civil_status) {
          case 'married':
            return 'Casado/a'
          case 'single':
            return 'Soltero/a'
          case 'divorced':
            return 'Divorciado/a'
          case 'widowed':
            return 'Viudo/a'
        }
      },
      _workerTypeCustomer() {
        switch (this.client.worker_type) {
          case 'dependent':
            return 'Dependiente'
          case 'independent':
            return 'Independiente'
        }
      }
    }
  }
</script>