<template>
  <b-card>
    <div class="my-2">
      <b-row class="mx-2">
          <b-col cols="6" md="4" class="my-1">
              <h4 class="font-weight-bolder">Tipo</h4>
              <h6 class="font-weight-normal">{{_typeProperty}}</h6>
          </b-col>
          <b-col cols="6" md="4" class="my-1">
              <h4 class="font-weight-bolder">Monto Propiedad</h4>
              <h6 class="font-weight-normal">{{property.amount_property}}</h6>
          </b-col>
          <b-col cols="6" md="4" class="my-1">
              <h4 class="font-weight-bolder">Monto Crédito</h4>
              <h6 class="font-weight-normal">{{property.amount_property - property.amount_foot}}</h6>
          </b-col>
          <!-- <b-col cols="6" md="4" class="my-1">
              <h4 class="font-weight-bolder">Plazo Crédito</h4>
              <h6 class="font-weight-normal">{{property.credit_term}}</h6>
          </b-col> -->
          <b-col cols="6" md="4" class="my-1">
              <h4 class="font-weight-bolder">Tipo Subsidio</h4>
              <h6 class="font-weight-normal">{{_typeSubsidy}}</h6>
          </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
  export default {
    name: 'PreapprovalDetailProperty',
    props: ['property'],
    data() {
      return {
        listProperty: {
          type: '',
          amount_property : '',
          amount_foot: '',
          credit_term: '',
          subsidy: ''
        }
      }
    },
    computed: {
      _typeProperty() {
        return this.property.property_type === 'used' ? 'Usada' : 'Nueva'
      },
      _typeSubsidy() {
        switch (this.property.subsidy_type_id) {
          case '1':
            return 'DS01'
          case '2':
            return 'DS19'
          case '2':
            return 'DS49'
          case '4':
            return 'DS116'
          case null:
            return 'Ninguno'
          default:
            return 'Ninguno'
        }
      }
    },
  }
</script>