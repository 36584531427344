<template>
  <b-card>
    <b-form class="mt-2">
      <b-row>
        <b-col sm="12">
          <b-form-group label="Documento" label-for="document">
            <b-form-file
              id="document"
              browse-text="Escoger"
              @change="_previewDocument"
              accept=".pdf, .docx, .doc"
              placeholder="Selecciona un documento" />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group label="Estado" label-for="status">
            <v-select
              v-model="editForm.status"
              label="name"
              placeholder="Selecciona un Estado"
              :options="statusSelect"
              @input="$v.editForm.status.$touch()"
              :reduce="statusSelect => statusSelect.id">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
            <small class="text-danger">{{ statusErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group label="Descripción" label-for="description">
            <b-form-textarea rows="3"
              :state="descriptionErrors.length > 0 ? false:null"
              v-model="editForm.description"
              id="description"
              @input="$v.editForm.description.$touch()"/>
            <small class="text-danger">{{ descriptionErrors[0] }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <div class="d-flex align-items-end justify-content-end mr-1 mt-1">
          <b-form-group>
            <div>
              <b-overlay
                :show="overlayForm"
                opacity="0.5"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                @hidden="_onHidden()" >
                <b-button block
                  ref="button"
                  :disabled="overlayForm"
                  variant="primary"
                  @click.prevent="_editPreapproval()">
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"/>
                  <span class="align-middle">
                    Guardar
                  </span>
                </b-button>
              </b-overlay>
            </div>
          </b-form-group>
        </div>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
  import {mapActions} from 'vuex'
  import {required} from 'vuelidate/lib/validators'
  import ToastificationContent from '@core/components/toastification/ToastificationContent'

  export default {
    name: 'PreapprovalDetailDocument',
    props: ['document'],
    data() {
      return {
        overlayForm: false,
        documentPreapproval: '',
        nameDocumentPreapproval: '',
        statusSelect: [
          {id:'approved',name:'Aprobado'},
          {id:'pending',name:'Pendiente'},
          {id:'rejected',name:'Rechazado'}
        ],
        editForm: {
          id: '',
          status: '',
          description: '',
          file: [],
        }
      }
    },
    validations: {
      editForm: {
        status: {
          required,
        },
        description: {
          required,
        },
      },
    },
    beforeMount() {
      this._chargeDataDocument()
    },
    computed: {
      statusErrors () {
        const errors = []
        if (!this.$v.editForm.status.$dirty) return errors
        !this.$v.editForm.status.required && errors.push('El estado es obligatorio')
        return errors
      },
      descriptionErrors () {
        const errors = []
        if (!this.$v.editForm.description.$dirty) return errors
        !this.$v.editForm.description.required && errors.push('La descripción es obligatorio')
        return errors
      },
    },
    methods: {
      ...mapActions('preapprovals',['editPreapprovals']),
      async _editPreapproval () {
        this.$v.editForm.$touch()
        if (!this.$v.editForm.$invalid) {
          this.overlayForm = true
          await this.editPreapprovals(this.editForm)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Datos modificados con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'preapprovals'})
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Problemas al modificar los datos',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            this.overlayForm = false
          })
        }
      },
      _chargeDataDocument() {
        this.editForm.id = this.document.id
        this.editForm.status = this.document.status
        this.editForm.description = this.document.description
      },
      _previewDocument(event) {
        if(event.target.files[0].type === 'application/pdf' || event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
          this.documentPreapproval = URL.createObjectURL(event.target.files[0])
          this.nameDocumentPreapproval = event.target.files[0].name
          this.editForm.file = event.target.files[0]
        }else{
          document.getElementById("document").value = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No es un formato admitido',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      },
      _onHidden() {
        this.$refs.button.focus()
      },
    },
  }
</script>