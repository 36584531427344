<template>
  <div id="preapprovals-details">
    <preapprovals-details-list :preapproval="preapproval"></preapprovals-details-list>
  </div>
</template>

<script>
  import PreapprovalsDetailsList from './components/PreapprovalsDetailsList'

  export default{
    name: 'PreapprovalsDetails',
    components: {PreapprovalsDetailsList},
    props: ['preapproval'],
    data() {
      return {
      }
    },
  }
</script>