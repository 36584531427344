<template>
  <div>
    <b-tabs pills vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      nav-class="nav-left"
      nav-wrapper-class="col-md-3 col-12">
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"/>
          <span class="font-weight-bold">
            Cliente
          </span>
        </template>
        <preapproval-detail-client :client="preapproval.customer"></preapproval-detail-client>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="HomeIcon"
            size="18"
            class="mr-50"/>
          <span class="font-weight-bold">
            Propiedad
          </span>
        </template>
        <preapproval-detail-property :property="preapproval"></preapproval-detail-property>
      </b-tab>
      <b-tab v-if="preapproval.customer.codeudor !== null">
        <template #title>
          <feather-icon
            icon="UsersIcon"
            size="18"
            class="mr-50"/>
          <span class="font-weight-bold">
            Codeudor
          </span>
        </template>
        <preapproval-detail-codeudor :codeudor="preapproval.customer.codeudor"></preapproval-detail-codeudor>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="FileTextIcon"
            size="18"
            class="mr-50"/>
          <span class="font-weight-bold">
            Documentación
          </span>
        </template>
        <preapproval-detail-document :document="preapproval"></preapproval-detail-document>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import PreapprovalDetailClient from './details/PreapprovalDetailClient'
  import PreapprovalDetailDocument from './details/PreapprovalDetailDocument'
  import PreapprovalDetailProperty from './details/PreapprovalDetailProperty'
  import PreapprovalDetailCodeudor from './details/PreapprovalDetailCodeudor'

  export default{
    name: 'PreapprovalDetailsList',
    props: ['preapproval'],
    components:{
      PreapprovalDetailClient,
      PreapprovalDetailDocument,
      PreapprovalDetailProperty,
      PreapprovalDetailCodeudor
    },
    data() {
      return {
      }
    },
  }
</script>